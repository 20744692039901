<template>
    <div class="detail_box">
        <div class="info">
            <label>报备品牌</label>
            <p>腾讯企业邮箱</p>
        </div>
        <div class="info">
            <label>报备名称</label>
            <p>{{ data.reportName }}</p>
        </div>
        <div class="info" v-if="data.nicheCategory == 1">
            <label>客户</label>
            <p>{{ data.companyName }}</p>
        </div>
        <div class="info" v-if="data.nicheCategory == 2">
            <label>报备渠道</label>
            <p>{{ data.channelManageName }}</p>
        </div>
        <div class="info">
            <label>域名</label>
            <p>{{ data.reportDomain }}</p>
        </div>

        <div class="info">
            <label>所在地</label>
            <p>
                {{
                    data.areaType == 1
                        ? $address.address(data.provinceId, data.cityId)
                        : data.areaType == 2
                        ? '国外/' + data.abroadArea
                        : '- -'
                }}
            </p>
        </div>
        <div class="info">
            <label>报备理由</label>
            <p>{{ data.reportReason }}</p>
        </div>
        <div class="info">
            <label>联系人</label>
            <p>{{ data.contactName }}</p>
        </div>
        <div class="info">
            <label>联系手机</label>
            <p>{{ data.contactPhone }}</p>
        </div>
        <div class="info">
            <label>预计用户数</label>
            <p>
                {{
                    data.estimateNumber
                        ? $MailStatus.echoEstimateNumber(data.estimateNumber) + '人'
                        : '- -'
                }}
            </p>
        </div>
        <div class="info">
            <label>预计年限</label>
            <p>
                {{
                    data.estimateTerm
                        ? $MailStatus.echoEstimateTerm(data.estimateTerm) + '年'
                        : '- -'
                }}
            </p>
        </div>
        <div class="info">
            <label>预计合作时间</label>
            <p>{{ data.estimateCooperateTime }}</p>
        </div>
        <div class="info">
            <label>预计金额</label>
            <p>{{ data.estimateAmount }}</p>
        </div>
        <div class="info">
            <label>备注</label>
            <p>{{ data.reportRemark ? data.reportRemark : '- -' }}</p>
        </div>
        <div class="info">
            <label>申请时间</label>
            <p>{{ data.reportApplyTime ? data.reportApplyTime : '- -' }}</p>
        </div>
        <div class="info">
            <label>当前状态</label>
            <p>
                {{ $MailStatus.reportStatus(data.reportStatus) }} -
                {{ $MailStatus.trialStatus(data.trialStatus) }}
            </p>
        </div>
        <div class="info" v-if="data.emailVersionType">
            <label>邮箱版本</label>
            <p>
                {{
                    data.emailVersionType == 2
                        ? '新版'
                        : data.emailVersionType == 3
                        ? '旧版'
                        : '- -'
                }}
            </p>
        </div>
        <div class="info" v-if="data.emailVersionType">
            <label>{{
                data.emailVersionType == 2
                    ? '企业ID'
                    : data.emailVersionType == 3
                    ? '管理员账号'
                    : ''
            }}</label>
            <p>
                {{ data.emailVersionTypeName ? data.emailVersionTypeName : '- -' }}
            </p>
        </div>
        <div class="info">
            <label>回收时间</label>
            <p>{{ data.reportExpireTime ? data.reportExpireTime : '- -' }}</p>
        </div>
        <div class="info">
            <label>试用到期时间</label>
            <p>{{ data.trialExpireTime ? data.trialExpireTime : '- -' }}</p>
        </div>
        <div class="info">
            <label>试用用户数</label>
            <p>
                {{ data.trialNumber ? data.trialNumber : '- -' }}
            </p>
        </div>
        <div class="info">
            <label>(申请)试用天数</label>
            <p>
                {{ data.trialDays ? data.trialDays : '- -' }}
            </p>
        </div>
        <div class="info">
            <label>申请人</label>
            <p>{{ data.adminName ? data.adminName : '- -' }}</p>
        </div>
        <div class="info">
            <label>所在部门</label>
            <p>{{ data.departmentName ? data.departmentName : '- -' }}</p>
        </div>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background: #2370eb; border-color: #d5d6df; color: #fff; margin-right: 20px"
                @click="onClose"
                >关 闭</el-button
            >
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data: {},
        };
    },
    methods: {
        echoData(row) {
            console.log(row);
            this.data = row;
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.detail_box {
    width: 100%;
    box-sizing: border-box;
    padding: 20px 0 50px 0;
    min-height: 100%;
    font-size: 14px;
    .info {
        width: 80%;
        margin: 0 auto;
        display: flex;
        position: relative;
        line-height: 36px;
        label {
            width: 100px;
            color: #666666;
        }
        p {
            flex: 1;
            color: #333;
            font-weight: 600;
        }
        .toast_box {
            position: absolute;
            right: -25px;
            top: 0;
            span {
                display: inline-block;
                width: 75px;
                text-align: center;
                height: 25px;
                line-height: 25px;
                border-radius: 3px;
            }
            .success {
                border: 1px solid #2370eb;
                i {
                    color: #2370eb;
                }
            }
            .warning {
                border: 1px solid #d0021b;
                i {
                    color: #d0021b;
                }
            }
            .wait {
                border: 1px solid #f5a623;
                i {
                    color: #f5a623;
                }
            }
        }
    }
    .bottom-but {
        width: 480px;
        line-height: 48px;
        text-align: right;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        bottom: 0;
        position: fixed;
        button {
            width: 90px;
            z-index: 1000;
        }
    }
}
</style>
