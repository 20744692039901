import http from '@/utils/http.js'

// 商机-报备（列表）
export function reportList(data) {
    return http({
        url: "/jasoboss/niche/list",
        method: "post",
        data
    })
}
// 商机-腾讯报备（单条）
export function reportGet(data) {
    return http({
        url: "/jasoboss/niche/get",
        method: "post",
        data
    })
}
// 腾讯报备添加
export function reportAdd(data) {
    return http({
        url: "/jasoboss/niche/add",
        method: "post",
        data
    })
}
// 客户列表
export function customerList(data) {
    return http({
        url: "/jasoboss/customer/list/admin/id",
        method: "post",
        data
    })
}
// 商机-报备编辑-试用
export function reportUpdate(data) {
    return http({
        url: "/jasoboss/niche/update",
        method: "post",
        data
    })
}
// 商机-操作
// 商机-历史
export function reportHistory(data) {
    return http({
        url: "/jasoboss/niche/history/list",
        method: "post",
        data
    })
}
// 商机-审核历史
export function reportAuditHistory(data) {
    return http({
        url: "/jasoboss/niche/audit/list",
        method: "post",
        data
    })
}
// 商机-审核
export function reportAudit(data) {
    return http({
        url: "/jasoboss/niche/audit/update",
        method: "post",
        data
    })
}
// 商机-域名列表
export function reportDomainList(data) {
    return http({
        url: "/jasoboss/niche/domain/list",
        method: "post",
        data
    })
}
// 商机-已报备设置
export function reportSetting(data) {
    return http({
        url: "/jasoboss/niche/setting",
        method: "post",
        data
    })
}
// 域名删除
export function delDomain(data) {
    return http({
        url: "/jasoboss/niche/domain/delete",
        method: "post",
        data
    })
}
// 商机-删除
export function reportDelete(data) {
    return http({
        url: "/jasoboss/niche/delete",
        method: "post",
        data
    })
}
// 账号添加
export function addAccount(data) {
    return http({
        url: "/jasoboss/niche/account/add",
        method: "post",
        data
    })
}
// 账号修改
export function updateAccount(data) {
    return http({
        url: "/jasoboss/niche/account/update",
        method: "post",
        data
    })
}
// 单个账号信息
export function getAccount(data) {
    return http({
        url: "/jasoboss/niche/account/get",
        method: "post",
        data
    })
}
// 账号信息列表
export function accountList(data) {
    return http({
        url: "/jasoboss/niche/account/list",
        method: "post",
        data
    })
}
// 账号统计
export function accountBrief(data) {
    return http({
        url: "/jasoboss/niche/account/brief/get",
        method: "post",
        data
    })
}
// 商机-报备的时候查询最大的报备数和已报备的数量
export function accountGet(data) {
    return http({
        url: "/jasoboss/niche/account/brief/admin/id/get",
        method: "post",
        data
    })
}
// 商机-渠道信息列表
export function channelInfoList(data) {
    return http({
        url: "/jasoboss/niche/channel/list",
        method: "post",
        data
    })
}
// 商机-渠道信息添加
export function addChannelInfo(data) {
    return http({
        url: "/jasoboss/niche/channel/add",
        method: "post",
        data
    })
}
// 商机-渠道信息修改
export function updateChannelInfo(data) {
    return http({
        url: "/jasoboss/niche/channel/update",
        method: "post",
        data
    })
}
// 商机-渠道信息列表(单个)
export function getChannelInfo(data) {
    return http({
        url: "/jasoboss/niche/channel/get",
        method: "post",
        data
    })
}
// 域名验证
export function checkDomain(data) {
    return http({
        url: "/jasoboss/niche/verify/domain",
        method: "post",
        data
    })
}
// 商机-验证域名（包括渠道域名）
export function verifyDomain(data) {
    return http({
        url: "/jasoboss/niche/verify/domain",
        method: "post",
        data
    })
}
// 商机报备统计
export function nicheReportBrief(data) {
    return http({
        url: "/jasoboss/niche/report/brief",
        method: "post",
        data
    })
}
// 商机试用统计
export function nicheTrialBrief(data) {
    return http({
        url: "/jasoboss/niche/trial/brief",
        method: "post",
        data
    })
}