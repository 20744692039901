<template>
    <div class="trial">
        <el-form
            ref="form"
            style="flex: 1; margin-top: 20px; margin-left: 40px"
            label-position="left"
            :model="form"
            label-width="100px"
        >
            <el-form-item label="报备名称">
                <span class="brand">{{ data.reportName }}</span>
            </el-form-item>
            <el-form-item label="客户">
                <span class="brand">{{ data.companyName }}</span>
            </el-form-item>
            <template v-if="data.trialStatus == 3">
                <el-form-item label="试用用户数" required>
                    <el-input
                        v-model="form.trialNumber"
                        size="small"
                        placeholder="请输入试用用户数"
                        @input="
                            () => {
                                form.trialNumber = form.trialNumber.replace(
                                    /[^0-9]/g,
                                    ''
                                );
                            }
                        "
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item label="邮箱版本" required>
                    <el-select
                        v-model="form.emailVersionType"
                        size="small"
                        style="width: 270px"
                        placeholder="请选择邮箱版本"
                        clearable
                        @change="form.emailVersionTypeName = ''"
                    >
                        <el-option label="旧版" :value="3"></el-option>
                        <el-option label="新版" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    required
                    v-show="form.emailVersionType"
                    :label="
                        form.emailVersionType == 3
                            ? '管理员账号'
                            : form.emailVersionType == 2
                            ? '企业ID'
                            : ''
                    "
                >
                    <el-input
                        v-model="form.emailVersionTypeName"
                        size="small"
                        clearable
                        :placeholder="
                            form.emailVersionType == 3
                                ? '请输入管理员账号'
                                : form.emailVersionType == 2
                                ? '请输入企业ID'
                                : ''
                        "
                    ></el-input>
                </el-form-item>
            </template>
            <el-form-item label="回收时间" v-if="data.reportStatus == 3">
                <el-date-picker
                    v-model="form.reportExpireTime"
                    size="small"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item
                label="试用到期时间"
                v-if="
                    data.trialStatus == 3 &&
                    data.reportStatus != 11 &&
                    data.reportStatus != 12 &&
                    data.reportStatus != 13 &&
                    data.reportStatus != 14
                "
            >
                <el-date-picker
                    v-model="form.trialExpireTime"
                    size="small"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="报备库">
                <el-select
                    v-model="form.libraryCategory"
                    size="small"
                    style="width: 270px"
                    placeholder="请选择"
                    clearable
                    @change="getQuota()"
                >
                    <el-option
                        v-for="item in tmFilingList"
                        :key="item.id"
                        :label="item.accountName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <p
                    style="color: #999; font-size: 12px"
                    v-if="form.libraryCategory"
                >
                    <i
                        class="el-icon-warning-outline"
                        style="color: #f5a623"
                    ></i>
                    剩余报备名额：{{
                        Quota.maxReportNumber - Quota.consumedNumber
                            ? Quota.maxReportNumber - Quota.consumedNumber
                            : 0
                    }}
                </p>
            </el-form-item>
            <el-form-item label="成交状态" v-if="data.reportStatus == 3">
                <el-checkbox v-model="checked">已成交</el-checkbox>
            </el-form-item>
            <el-form-item
                style="margin: 0; margin-top: -20px"
                v-if="data.reportStatus == 3"
            >
                <span style="color: #666666; font-size: 12px">
                    <i
                        class="iconfont icon-shangchuanshibaixian-copy"
                        style="color: #f5a623"
                    ></i>
                    标记为已成交状态后将不可修改</span
                >
            </el-form-item>
            <el-form-item label="备注">
                <el-input
                    type="textarea"
                    v-model="form.settingRemark"
                    size="small"
                    placeholder="请输入备注"
                    style="width: 270px"
                    :rows="5"
                ></el-input>
            </el-form-item>
        </el-form>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: 270px;
                "
                @click="onClose"
                >取消</el-button
            >

            <el-button
                round
                type="primary"
                style="background: #2370eb; border-color: #2370eb; color: #fff"
                size="mini"
                @click="onSubmit"
                >保存</el-button
            >
        </div>
    </div>
</template>
<script>
import { reportSetting, accountList, reportGet } from '@/api/report/report';
export default {
    data() {
        return {
            data: {},
            form: {
                id: '',
                reportExpireTime: '',
                libraryCategory: '',
                trialExpireTime: '',
                reportStatus: '',
                settingRemark: '',
                trialNumber: '',
                emailVersionType: '',
                emailVersionTypeName: '',
            },
            checked: false,
            tmFilingList: [],
            Quota: '',
        };
    },
    methods: {
        echoData(data) {
            for (let i in this.form) {
                this.form[i] = '';
            }
            this.data = data;
            this.form.id = data.id;

            this.checked = false;
            let obj = {
                param: { productType: 1 },
                pageNum: 0,
                pageSize: 0,
            };
            console.log(data);
            accountList(obj).then((res) => {
                this.tmFilingList = res.data.list;
            });
            if (data.trialStatus == 3) {
                let objs = {
                    param: {
                        id: data.id,
                    },
                };
                reportGet(objs).then((res) => {
                    if (res.code == 200) {
                        this.form.trialNumber = res.data.trialNumber;
                        this.form.emailVersionType = res.data.emailVersionType;
                        this.form.emailVersionTypeName =
                            res.data.emailVersionTypeName;
                    }
                });
            }
        },
        getQuota() {
            if (this.form.libraryCategory != '') {
                for (let i = 0; i < this.tmFilingList.length; i++) {
                    if (this.tmFilingList[i].id == this.form.libraryCategory) {
                        return (this.Quota = this.tmFilingList[i]);
                    }
                }
            }
        },
        onSubmit() {
            let data = {
                param: this.form,
            };
            if (this.data.trialStatus == 3) {
                if (!this.form.trialNumber) {
                    return this.$message.error('请输入试用用户数');
                }
                if (!this.form.emailVersionType) {
                    return this.$message.error('请选择邮箱版本');
                }
                if (
                    this.form.emailVersionType == 3 &&
                    !this.form.emailVersionTypeName
                ) {
                    return this.$message.error('请输入管理员账号');
                }
                if (
                    this.form.emailVersionType == 2 &&
                    !this.form.emailVersionTypeName
                ) {
                    return this.$message.error('请输入请输入企业ID');
                }
            }
            if (this.checked) {
                data.param.reportStatus = 18;
            }
            if (this.Quota.maxReportNumber - this.Quota.consumedNumber <= 0) {
                return this.$message.error('剩余报备名额不足');
            }
            reportSetting(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('设置成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            this.$emit('close');
            for (let i in this.form) {
                this.form[i] = '';
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.trial {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .brand {
        display: inline-block;
        width: 260px;
        padding-left: 10px;
        color: #333333;
        font-size: 14px;
        border-radius: 2px;
    }
    .el-input {
        width: 270px;
    }
    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        bottom: 0;
        position: fixed;
        button {
            width: 90px;
            z-index: 1000;
        }
    }
}
</style>
