import http from '@/utils/http.js'

// 我的客户列表
export function meCustomerList(data) {
    return http({
        url: "/jasoboss/customer/list",
        method: "post",
        data
    })
}
// 协作客户
export function assistCustomerList(data) {
    return http({
        url: "/jasoboss/customer/list/synergy",
        method: "post",
        data
    })
}
// 部门客户
export function dempCustomerList(data) {
    return http({
        url: "/jasoboss/customer/list/department",
        method: "post",
        data
    })
}
// 部门协作客户
export function dempAssistCustomerList(data) {
    return http({
        url: "/jasoboss/customer/list/synergy/department",
        method: "post",
        data
    })
}
// 添加客户
export function addCustomer(data) {
    return http({
        url: "/jasoboss/customer/add",
        method: "post",
        data
    })
}
// 修改客户
export function editCustomer(data) {
    return http({
        url: "/jasoboss/customer/update",
        method: "post",
        data
    })
}
// 查询分配协作人列表
export function AddAssistList(data) {
    return http({
        url: "/jasoboss/admin/role/type/list",
        method: "post",
        data
    })
}
// 查询协作人列表
export function assistList(data) {
    return http({
        url: "/jasoboss/admin/role/type/list",
        method: "post",
        data
    })
}
// 查询跟进人列表
export function followList(data) {
    return http({
        url: "/jasoboss/admin/role/type/list",
        method: "post",
        data
    })
}
// 变更跟进人和协作人
export function changeOwner(data) {
    return http({
        url: "/jasoboss/customer/change/owner",
        method: "post",
        data
    })
}
//客户状态更新（包括置顶）
export function changeStatus(data) {
    return http({
        url: "/jasoboss/customer/update/status",
        method: "post",
        data
    })
}

// 获取单个客户
export function getCustomerId(data) {
    return http({
        url: "/jasoboss/customer/get",
        method: "post",
        data
    })
}
// 获取客户关联业绩预估
export function customerEstimate(data) {
    return http({
        url: "/jasoboss/performance/forecast/list/customer/id",
        method: "post",
        data
    })
}
// 获取客户关联线索
export function relationClue(data) {
    return http({
        url: "/jasoboss/clue/list/customer/id",
        method: "post",
        data
    })
}
// 获取客户关联联系人
export function contactList(data) {
    return http({
        url: "/jasoboss/customer/contact/list",
        method: "post",
        data
    })
}
// 添加关联联系人
export function contactAdd(data) {
    return http({
        url: "/jasoboss/customer/contact/add",
        method: "post",
        data
    })
}
// 获取关联联系人
export function clueGet(data) {
    return http({
        url: "/jasoboss/clue/get",
        method: "post",
        data
    })
}
// 修改联系人
export function contactEdit(data) {
    return http({
        url: "/jasoboss/customer/contact/update",
        method: "post",
        data
    })
}

// 根据customerID获取合同列表
export function customerId(data) {
    return http({
        url: "/jasoboss/contract/list",
        method: "post",
        data
    })
}

// 腾讯报备统计
export function reportBrief(data) {
    return http({
        url: "/jasoboss/customer/tencent/report/brief",
        method: "post",
        data
    })
}

// 腾讯试用统计
export function trialBrief(data) {
    return http({
        url: "/jasoboss/customer/tencent/trial/brief",
        method: "post",
        data
    })
}
// 阿里报备统计
export function aliReportBrief(data) {
    return http({
        url: "/jasoboss/customer/ali/report/brief",
        method: "post",
        data
    })
}

//  阿里试用统计
export function aliTrialBrief(data) {
    return http({
        url: "/jasoboss/customer/ali/trial/brief",
        method: "post",
        data
    })
}
// 个人客户表头统计
export function statistics(data) {
    return http({
        url: "/jasoboss/customer/statistics/admin/id",
        method: "post",
        data
    })
}

//  部门客户表头统计
export function statisticsDemp(data) {
    return http({
        url: "/jasoboss/customer/statistics/department/id",
        method: "post",
        data
    })
}
// 阿里非已报备和申请中的所有客户
export function aliNotReport(data) {
    return http({
        url: "/jasoboss/customer/list/ali/not/report",
        method: "post",
        data
    })
}

//  腾讯非已报备和申请中的所有客户
export function tmNotReport(data) {
    return http({
        url: "/jasoboss/customer/list/tencent/not/report",
        method: "post",
        data
    })
}
//  阿里主域名审核
export function updateDomain(data) {
    return http({
        url: "/jasoboss/customer/ali/report/update/detail",
        method: "post",
        data
    })
}
//  腾讯延期放弃申请
export function tencentApply(data) {
    return http({
        url: "/jasoboss/customer/tencent/apply",
        method: "post",
        data
    })
}
//  腾讯延期放弃申请
export function aliApply(data) {
    return http({
        url: "/jasoboss/customer/ali/apply",
        method: "post",
        data
    })
}
//  腾讯个人试用总数统计
export function tmTrialRrying(data) {
    return http({
        url: "/jasoboss/customer/tencent/trial/trying/admin/id",
        method: "post",
        data
    })
}
//  腾讯部门试用总数统计
export function tmDempTrialRrying(data) {
    return http({
        url: "/jasoboss/customer/tencent/trial/trying/department/id",
        method: "post",
        data
    })
}
//  阿里个人试用总数统计
export function aliTrialRrying(data) {
    return http({
        url: "/jasoboss/customer/ali/trial/trying/admin/id",
        method: "post",
        data
    })
}
//  阿里部门试用总数统计
export function aliDempTrialRrying(data) {
    return http({
        url: "/jasoboss/customer/ali/trial/trying/department/id",
        method: "post",
        data
    })
}
//  腾讯报备表头统计----部门
export function tmBriefDemp(data) {
    return http({
        url: "/jasoboss/customer/tencent/report/brief/department",
        method: "post",
        data
    })
}
//  阿里报备表头统计---部门
export function aliBriefDemp(data) {
    return http({
        url: "/jasoboss/customer/ali/report/brief/department",
        method: "post",
        data
    })
}
//  腾讯报备客户列表
export function tmCustomer(data) {
    return http({
        url: "/jasoboss/customer/list/tencent",
        method: "post",
        data
    })
}


//  阿里报备客户列表
export function aliCustomer(data) {
    return http({
        url: "/jasoboss/customer/list/ali",
        method: "post",
        data
    })
}
//  删除关联域名
export function domainDetele(data) {
    return http({
        url: "/jasoboss/customer/domain/delete",
        method: "post",
        data
    })
}
// 获取单个客户
export function getCustomerAdmin(data) {
    return http({
        url: "/jasoboss/customer/list/admin/id",
        method: "post",
        data
    })
}

// 获取单个客户  认证的客户
export function getCustomerAuthentication(data) {
    return http({
        url: "/jasoboss/customer/list/authentication",
        method: "post",
        data
    })
}
// 添加到我的客户里面
export function customerAddMy(data) {
    return http({
        url: "/jasoboss/customer/add/my",
        method: "post",
        data
    })
}
//包括置顶
export function customerTop(data) {
    return http({
        url: "/jasoboss/customer/top",
        method: "post",
        data
    })
}